import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { CognitoAuthService, ConnectUser } from '@techspert-io/auth';
import { IClient } from '@techspert-io/clients';
import {
  IExpertCallAction,
  IExpertCallActionDialIn,
} from '@techspert-io/conferences';
import { IExpertProfile } from '@techspert-io/expertProfiles';
import { IExpert } from '@techspert-io/experts';
import {
  IOpportunity,
  IOpportunityClientContact,
} from '@techspert-io/opportunities';
import { UserService, getOfficeLocationAddress } from '@techspert-io/users';
import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Email } from '../../../models/email';
import {
  ActionEmailRecipientType,
  ActionEmailType,
  ConferenceService,
} from '../../../services/conference.service';
import { CountryService } from '../../../services/country.service';
import { EmailType } from '../schedule-models';
import * as Templates from './email-content';
import { expertIntroHtml, replyInstructionsHtml } from './email-content';

export interface IEmailSentEvent {
  primaryRecipient: string;
  blind: boolean;
}

interface IRecipient {
  address: string;
  isContact: boolean;
}

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit, OnChanges {
  private templates = Templates.templates;
  private expertBlindInstructionsHtml = Templates.expertBlindInstructionsHtml;
  private expertBlindInstructionsText = Templates.expertBlindInstructionsText;
  private clientBlindInstructionsText = `${Templates.clientBlindInstructionsHeaderText}\n${Templates.clientBlindInstructionsBodyText}`;
  private complianceText = Templates.complianceText;
  private paymentTerms = Templates.paymentTerms;
  private joinWarning = Templates.joinWarning;

  @Output() onEmailSent = new EventEmitter<IEmailSentEvent>();

  // Input data
  @Input() callAction: IExpertCallAction;
  @Input() expert: IExpert;
  @Input() expertProfile: IExpertProfile;
  @Input() primaryClientContact: IOpportunityClientContact;
  @Input() client: IClient;
  @Input() opportunity: IOpportunity;

  // email config
  @Input() blind: boolean;
  @Input() type: EmailType;
  @Input() primaryRecipient: 'expert' | 'client';

  public moment = moment;
  public showBtnLoader: boolean = false;
  public emailSentSuccess: boolean = false;
  public emailSentFailure: boolean = false;
  public emailTemplate: string;
  public directRecipient: IRecipient;
  public ccRecipientArray: IRecipient[] = [];
  public emailSubject: string;
  public sharedEmail: string = 'hello@techspert.com';
  expertDatetime: string;
  expertZoneAbbr: string;
  clientDatetime: string;
  clientZoneAbbr: string;
  users$: Observable<ConnectUser[]>;
  fromUser: ConnectUser;

  get hasValidRecipients(): boolean {
    return [...this.ccRecipientArray, this.directRecipient].every(
      (r) => r && r.isContact
    );
  }

  constructor(
    private cognitoAuthService: CognitoAuthService,
    private countryService: CountryService,
    private conferenceService: ConferenceService,
    private usersService: UserService
  ) {}

  public ngOnInit(): void {
    this.fromUser = this.cognitoAuthService.loggedInUser;

    this.setupFromUsersOptions(this.fromUser);

    this.ccRecipientArray = [];
    if (this.callAction) {
      this.getEmergencyContact().subscribe((callActionUser) => {
        this.setupRecipients(callActionUser?.email);

        ({ formattedDateTime: this.expertDatetime, abbr: this.expertZoneAbbr } =
          this.getTimeAndTZAbbreviation(
            this.callAction.datetime,
            this.expertProfile.timezoneName
          ));
        ({ formattedDateTime: this.clientDatetime, abbr: this.clientZoneAbbr } =
          this.getTimeAndTZAbbreviation(
            this.callAction.datetime,
            this.primaryClientContact.timezone.name
          ));

        const subjectBase =
          this.type === 'cancel'
            ? `Techspert call cancelled: ${this.callAction.externalName}`
            : `Techspert call confirmed: ${this.callAction.externalName}`;

        const expertName =
          this.primaryRecipient === 'client'
            ? `(${this.expertName(
                this.expert,
                this.opportunity.excludeExpertPIIForClientEmails
              )})`
            : '';

        this.emailSubject = [subjectBase, expertName].join(' ').trim();

        this.emailTemplate = this.replacePlaceholders(
          this.createEmailTemplate()
        ).replace(new RegExp('<br />', 'g'), '<div>&nbsp;</div>');
      });
    }
  }

  public ngOnChanges(): void {
    this.ngOnInit();
  }

  public setFromUser(userJSON: string): void {
    this.fromUser = JSON.parse(userJSON);
    if (!this.ccRecipientArray.some((r) => r.address === this.fromUser.email)) {
      this.ccRecipientArray.push({
        address: this.fromUser.email,
        isContact: true,
      });
    }

    this.emailTemplate = this.replacePlaceholders(
      this.createEmailTemplate()
    ).replace(new RegExp('<br />', 'g'), '<div>&nbsp;</div>');
  }

  private createEmailTemplate(): string {
    return [
      this.expertBlindInstructions(),
      this.templates.intro[this.primaryRecipient],
      this.firstSection(),
      this.callHostDetails(),
      this.callDetails(),
      this.expertBio(),
      this.screenerResult(),
      this.compliance(),
      this.joinThreshold(),
      this.paymentTerm(),
      this.footer(),
    ].join('');
  }

  private footer() {
    return this.blind
      ? this.templates.footer[this.primaryRecipient]
      : this.templates.footer.open;
  }

  private compliance() {
    return this.type !== 'cancel' &&
      (!this.blind || this.primaryRecipient === 'expert')
      ? this.complianceText
      : '';
  }

  private paymentTerm() {
    return this.type !== 'cancel' && this.primaryRecipient === 'expert'
      ? this.paymentTerms
      : '';
  }

  private joinThreshold() {
    return this.type !== 'cancel' ? this.joinWarning : '';
  }

  private callHostDetails() {
    if (
      this.type === 'cancel' ||
      !this.callAction.isClientHostEnabled ||
      this.primaryRecipient === 'expert'
    ) {
      return '';
    }

    return this.templates.callHostDetails[
      this.callAction?.callType || 'zoom-techspert'
    ];
  }

  private callDetails() {
    if (this.type === 'cancel') {
      return '';
    }

    const type =
      this.callAction.isClientHostEnabled &&
      this.callAction.joinMetadata?.hostlink &&
      this.primaryRecipient === 'client'
        ? 'guest-link'
        : 'standard';

    return (
      this.templates.meetingDetails[
        this.callAction?.callType || 'zoom-techspert'
      ][type] || ''
    );
  }

  private expertBio() {
    return this.type !== 'cancel' &&
      this.blind &&
      this.opportunity.enrichedSchedulerInvite &&
      this.primaryRecipient !== 'expert' &&
      this.expert.bio
      ? ['<b><u>About your expert</u></b>', this.expert.bio].join('')
      : '';
  }

  private screenerResult() {
    return this.type !== 'cancel' &&
      this.blind &&
      this.primaryRecipient !== 'expert' &&
      this.opportunity.enrichedSchedulerInvite &&
      this.expert.screenerResponse
      ? ['<b><u>Screener response</u></b>', this.expert.screenerResponse].join(
          ''
        )
      : '';
  }

  private firstSection() {
    return this.blind
      ? this.templates[this.type].firstSection[this.primaryRecipient]
      : this.templates[this.type].firstSection[
          this.primaryRecipient === 'expert' ? 'expertOpen' : 'clientOpen'
        ];
  }

  private expertBlindInstructions() {
    return this.type !== 'cancel' &&
      this.blind &&
      this.primaryRecipient === 'expert'
      ? `${this.expertBlindInstructionsHtml}<br />`
      : '';
  }

  public sendEmail(): void {
    const typeToActionTypeDict: Record<EmailType, ActionEmailType> = {
      new: ActionEmailType.SCHEDULE,
      update: ActionEmailType.RESCHEDULE,
      cancel: ActionEmailType.CANCELLATION,
    };

    const newMail = new Email();
    newMail.htmlContent = this.emailTemplate;
    newMail.textContent = this.createPlainText();
    newMail.recipient = this.directRecipient.address;
    newMail.sender = {
      email: this.fromUser.email,
      name: `${this.fromUser.firstName} ${this.fromUser.lastName}`,
    };
    newMail.cc = this.ccRecipientArray.map((r) => r.address);
    newMail.subject = this.emailSubject;
    newMail.emailType = 'conference';

    this.showBtnLoader = true;

    const reminder =
      this.primaryRecipient === 'expert' &&
      this.opportunity.conferenceReminderDefaultSettings &&
      this.opportunity.conferenceReminderDefaultSettings.enabled &&
      ['new', 'update'].includes(this.type);

    this.conferenceService
      .sendCallActionEmail({
        email: newMail,
        type: typeToActionTypeDict[this.type],
        recipientType: !this.blind
          ? ActionEmailRecipientType.NONBLIND
          : this.primaryRecipient === 'client'
          ? ActionEmailRecipientType.CLIENT
          : ActionEmailRecipientType.EXPERT,
        expertActionId: this.callAction.expertActionId,
        expertTimezone: this.expertProfile.timezoneName,
        reminderHtml: reminder
          ? this.createReminderEmailHtml(newMail.htmlContent)
          : undefined,
      })
      .pipe(
        tap(() => {
          this.emailSentSuccess = true;

          this.onEmailSent.emit({
            primaryRecipient: this.primaryRecipient,
            blind: this.blind,
          });
          this.showBtnLoader = false;
        }),
        catchError((error) => {
          console.error(error);
          this.emailSentFailure = true;
          return EMPTY;
        })
      )
      .subscribe();
  }

  private getEmergencyContact(): Observable<ConnectUser | undefined> {
    return this.callAction.emergencyContact
      ? this.usersService.getById(this.callAction.emergencyContact)
      : of(undefined);
  }

  private createReminderEmailHtml(content: string): string {
    return content
      .replace(replyInstructionsHtml, '')
      .replace(this.replacePlaceholders(expertIntroHtml), '');
  }

  public addEmailToRecipientList(
    destination: string,
    { currentTarget }: KeyboardEvent
  ): void {
    if (destination === 'cc' && currentTarget instanceof HTMLInputElement) {
      const sanitize = (val: string): string => `${val}`.toLowerCase().trim();
      const sanitizedAddress = sanitize(currentTarget.value);

      if (
        sanitizedAddress &&
        !this.ccRecipientArray.some(
          (r) => sanitize(r.address) === sanitizedAddress
        )
      ) {
        this.ccRecipientArray.push({
          address: sanitizedAddress,
          isContact:
            this.opportunity.clientContacts.some(
              (c) => sanitize(c.email) === sanitizedAddress
            ) || sanitizedAddress.endsWith('techspert.com'),
        });
        currentTarget.value = '';
      }
    }
  }

  public removeEmailFromArray(destination: string, email: string): void {
    if (destination === 'cc') {
      this.ccRecipientArray = this.ccRecipientArray.filter(
        (r) => r.address !== email
      );
    }
  }

  public removeLastEmail(destination: string, event: KeyboardEvent): void {
    if (
      destination === 'cc' &&
      !event.repeat &&
      !(event.currentTarget as HTMLInputElement).value
    ) {
      this.ccRecipientArray.pop();
    }
  }

  public addRecipientEmail({ currentTarget }: KeyboardEvent): void {
    if (currentTarget instanceof HTMLInputElement) {
      const sanitize = (val: string): string => `${val}`.toLowerCase().trim();
      const sanatizedAddress = sanitize(currentTarget.value);

      if (this.primaryRecipient === 'client') {
        this.directRecipient = {
          address: sanatizedAddress,
          isContact: this.opportunity.clientContacts.some(
            (c) => sanitize(c.email) === sanatizedAddress
          ),
        };
      } else {
        this.directRecipient = {
          address: sanatizedAddress,
          isContact: true,
        };
      }
      currentTarget.value = '';
    }
  }

  public removeRecipientEmail(): void {
    this.directRecipient = undefined;
  }

  // Should client country come from primary client contact, not Client?
  private createDialInString(html: boolean): string {
    const addCountryHeading = (dialIn: IExpertCallActionDialIn): string => {
      const countryName = this.countryService.getCountryByCode(dialIn.country);

      let countryHeading: string = '';
      if (html) {
        countryHeading += `<div style="text-decoration: underline;">`;
      }
      countryHeading += `If you are calling from ${
        countryName || dialIn.country
      } dial:`;
      countryHeading += html ? `</div>` : `\n`;
      return countryHeading;
    };

    const addCountryNumber = (dialIn: IExpertCallActionDialIn): string => {
      let numberLine = '';
      if (html) {
        numberLine += `<div style="padding-top: 5px; padding-left: 2rem">`;
      }
      numberLine += `${dialIn.number}`;
      if (dialIn.city) {
        numberLine += ` (${dialIn.city}) `;
      }
      numberLine += html ? `</div>` : `\n`;
      return numberLine;
    };

    let dialInString: string = '';
    if (
      this.callAction.callType === 'zoom-techspert' &&
      this.callAction?.joinMetadata?.dialIns &&
      this.callAction.joinMetadata.dialIns.length > 0
    ) {
      if (this.blind) {
        const country =
          this.primaryRecipient === 'expert'
            ? this.expertProfile.country
            : this.primaryClientContact.country;
        let isCountryHeading = false;
        dialInString += this.callAction.joinMetadata.dialIns.reduce<string>(
          (prev, curr) => {
            if (curr.country === country) {
              if (!isCountryHeading && curr.country === country) {
                isCountryHeading = true;
                prev += addCountryHeading(curr);
              }
              prev += addCountryNumber(curr);
            }
            return prev;
          },
          ''
        );
      } else {
        let isExpertCountryHeading: boolean = false;
        let isClientCountryHeading: boolean =
          this.expertProfile.country === this.primaryClientContact.country;
        dialInString += this.callAction.joinMetadata.dialIns.reduce<string>(
          (prev, curr) => {
            if (
              curr.country === this.expertProfile.country ||
              curr.country === this.primaryClientContact.country
            ) {
              if (
                !isExpertCountryHeading &&
                curr.country === this.expertProfile.country
              ) {
                isExpertCountryHeading = true;
                if (isClientCountryHeading) {
                  prev += html ? `<br>` : `\n`;
                }
                prev += addCountryHeading(curr);
              }
              if (
                !isClientCountryHeading &&
                curr.country === this.primaryClientContact.country
              ) {
                isClientCountryHeading = true;
                if (isExpertCountryHeading) {
                  prev += html ? `<br>` : `\n`;
                }
                prev += addCountryHeading(curr);
              }
              prev += addCountryNumber(curr);
            }
            return prev;
          },
          ''
        );
      }
    }

    const zoomLink = html
      ? `<a href="https://zoom.us/zoomconference">https://zoom.us/zoomconference</a>`
      : 'https://zoom.us/zoomconference';
    const dialInHeader = html
      ? `<div style="font-weight: bold">Connecting via dial-in:</div>`
      : 'Connecting via dial-in:\n';
    const dialInfooter = html
      ? `<div>You can find a full list of dial-ins at: ${zoomLink}</div><br />`
      : `You can find a full list of dial-ins at: ${zoomLink}\n`;

    return dialInString
      ? `${dialInHeader}${dialInString}${html ? '<br />' : '\n'}${dialInfooter}`
      : '';
  }

  private constructSyntacticallyCorrectCallTimes(
    clientDatetime: string,
    expertDatetime: string,
    clientZoneAbbr: string,
    expertZoneAbbr: string,
    primaryRecipient: string,
    blind: boolean
  ): string {
    const expertDate = expertDatetime.split(', ')[0];
    const expertTime = expertDatetime.split(', ')[1];
    const clientDate = clientDatetime.split(', ')[0];
    const clientTime = clientDatetime.split(', ')[1];
    if (blind) {
      if (primaryRecipient === 'client') {
        return `${clientDatetime} (${clientZoneAbbr})`;
      }
      if (primaryRecipient === 'expert') {
        return `${expertDatetime} (${expertZoneAbbr})`;
      }
    } else {
      if (clientDatetime === expertDatetime) {
        // if expert and client are in the same timezone
        return `${expertDatetime} (${expertZoneAbbr})`;
      } else if (clientDate === expertDate && clientTime !== expertTime) {
        //if expert and client call on the same day but at different times
        if (primaryRecipient === 'client') {
          return `${clientDate}, ${clientTime} (${clientZoneAbbr}) / ${expertTime} (${expertZoneAbbr})`;
        }
        if (primaryRecipient === 'expert') {
          return `${clientDate}, ${expertTime} (${expertZoneAbbr}) / ${clientTime} (${clientZoneAbbr})`;
        }
      } else if (clientDate !== expertDate) {
        //if expert and client call happens on seperate days
        if (primaryRecipient === 'client') {
          return `${clientDatetime} (${clientZoneAbbr}) / ${expertDatetime} (${expertZoneAbbr})`;
        }
        if (primaryRecipient === 'expert') {
          return `${expertDatetime} (${expertZoneAbbr}) / ${clientDatetime} (${clientZoneAbbr})`;
        }
      }
    }
  }

  // consider [clientDateAndTimezone], [expertDateAndTimezone], [openDateAndTimezone] replaces & use the last of these to not repeat same date when expert/client same tz
  private replacePlaceholders(templateStr: string): string {
    const callTime = this.constructSyntacticallyCorrectCallTimes(
      this.clientDatetime,
      this.expertDatetime,
      this.clientZoneAbbr,
      this.expertZoneAbbr,
      this.primaryRecipient,
      this.blind
    );

    const expertSegment =
      this.opportunity.segments[this.expert.opportunitySegmentId];

    return templateStr
      .replace(/\[callTime\]/g, callTime)
      .replace(
        /\[webLink\]/g,
        (this.callAction.isClientHostEnabled &&
        this.primaryRecipient === 'client'
          ? this.callAction.joinMetadata?.hostlink
          : this.callAction.joinMetadata?.weblink) || ''
      )
      .replace(/\[guestLink\]/g, this.callAction.joinMetadata?.weblink)
      .replace(/\[conferenceTopic\]/g, this.callAction.externalName)
      .replace(/\[expertFirstName\]/g, this.expert.firstName)
      .replace(/\[expertLastName\]/g, this.expert.lastName)
      .replace(
        /\[expertName\]/g,
        this.expertName(
          this.expert,
          this.opportunity.excludeExpertPIIForClientEmails
        )
      )
      .replace(/\[geographicTarget\]/g, expertSegment?.geography || '')
      .replace(/\[profileType\]/g, expertSegment?.segment || '')
      .replace(/\[clientRepFirstName\]/g, this.primaryClientContact.firstName)
      .replace(/\[clientRepLastName\]/g, this.primaryClientContact.lastName)
      .replace(/\[clientName\]/g, this.client.clientName)
      .replace(/\[userFirstName\]/g, this.fromUser.firstName || '')
      .replace(/\[userLastName\]/g, this.fromUser.lastName || '')
      .replace(/\[userEmail\]/g, this.fromUser.email)
      .replace(/\[zoomId\]/g, this.callAction.joinMetadata?.meetingId)
      .replace(/\[zoomPassword\]/g, this.callAction.joinMetadata?.password)
      .replace(
        /\[office_location\]/g,
        getOfficeLocationAddress(this.fromUser.profile?.officeLocation)
      )
      .replace(/\[dialIns\]/g, this.createDialInString(true));
  }

  private expertName(expert: IExpert, excludePII: boolean) {
    if (excludePII) {
      if (!expert.firstNameAlias && !expert.lastNameAlias) {
        return [
          expert.firstName.substring(0, 1),
          expert.lastName.substring(0, 1),
        ]
          .join('')
          .trim();
      }

      return [
        expert.firstNameAlias || expert.firstName.substring(0, 1) || '',
        expert.lastNameAlias || expert.lastName.substring(0, 1) || '',
      ]
        .join(' ')
        .trim();
    }

    return [
      expert.firstNameAlias || expert.firstName || '',
      expert.lastNameAlias || expert.lastName || '',
    ]
      .join(' ')
      .trim();
  }

  private createPlainText(): string {
    if (this.type === 'cancel') return 'Call cancelled';

    let text: string = '';
    if (this.primaryRecipient === 'expert') {
      if (this.blind) {
        text += `${this.expertBlindInstructionsText}\n\n`;
      }
      text += 'The selected time to call is ';
      text += this.expertDatetime + ' (' + this.expertZoneAbbr + ')';
      if (!this.blind) {
        text += ' / ' + this.clientDatetime + ' (' + this.clientZoneAbbr + ')';
      }
    } else {
      // else assume config is client
      if (this.blind) {
        text += `${this.clientBlindInstructionsText}\n\n`;
      }
      text += 'The selected time to call is ';
      text += this.clientDatetime + ' (' + this.clientZoneAbbr + ')';
      if (!this.blind) {
        text += ' / ' + this.expertDatetime + ' (' + this.expertZoneAbbr + ')';
      }
    }
    if (this.callAction.callType === 'zoom-techspert') {
      const dialInString = this.createDialInString(false);
      text +=
        `. You can join this call using the instructions below and please accept this calendar invitation to confirm the call.\n` +
        `Meeting type: Zoom\n` +
        (this.callAction.joinMetadata?.meetingId
          ? `Meeting Id: ${this.callAction.joinMetadata.meetingId}\n`
          : '') +
        (this.callAction.joinMetadata?.password
          ? `Meeting password: ${this.callAction.joinMetadata.password}\n`
          : '') +
        (this.callAction.joinMetadata?.weblink
          ? `You can access the call from: [conference_meeting_link]\n`
          : '') +
        (dialInString
          ? `\n${dialInString}`
          : '\nYou can find a full list of dial-ins at: https://zoom.us/zoomconference');
    } else {
      text +=
        ', please accept this calendar invitation to confirm your attendence.';
    }

    if (!this.blind || this.primaryRecipient === 'expert') {
      text += `\n\nPlease ensure you are familiar with our terms of use and privacy policy (https://techspert.com/terms-and-conditions-for-experts). Some key points we wish to highlight:
      1. You confirm that you will NOT discuss your current employer.
      2. You confirm that you are NOT aware of any present conflicts of interest that would impact your ability to participate in the project.
      3. You confirm that you will NOT disclose any Confidential Information including any that arises from an NDA or any other Contractual Agreement.`;
    }

    return text;
  }

  private setupFromUsersOptions(userToCheck: ConnectUser): void {
    this.users$ = this.usersService.getAll({ userTypes: ['PM'] }).pipe(
      map((users) =>
        users.some((u) => u.email === userToCheck.email)
          ? users
          : [...users, userToCheck]
      ),
      map((u) => u.sort((a, b) => (a.firstName < b.firstName ? -1 : 1)))
    );
  }

  private setupRecipients(emergencyCallActionEmail = ''): void {
    const sanitize = (val: string): string => `${val}`.toLowerCase().trim();

    const ccContacts = [
      ...new Set(
        [
          ...this.opportunity.clientContacts.map(
            (contactObj) => contactObj.email
          ),
          emergencyCallActionEmail,
        ]
          .map((c) => sanitize(c))
          .filter(Boolean)
          .filter((c) => c !== sanitize(this.fromUser?.email))
      ),
    ];
    const primaryContactEmail = sanitize(this.primaryClientContact.email);

    switch (this.primaryRecipient) {
      case 'expert': {
        this.directRecipient = {
          address: this.expert.primaryEmail,
          isContact: true,
        };

        if (!this.blind) {
          this.ccRecipientArray = ccContacts.map((r) => ({
            address: r,
            isContact: true,
          }));

          if (
            !this.ccRecipientArray.some(
              (r) => r.address === primaryContactEmail
            )
          ) {
            this.ccRecipientArray.unshift({
              address: primaryContactEmail,
              isContact: false,
            });
          }
        } else if (emergencyCallActionEmail) {
          this.ccRecipientArray.push({
            address: emergencyCallActionEmail,
            isContact: true,
          });
        }

        if (
          !this.ccRecipientArray.some((c) => c.address === this.fromUser.email)
        ) {
          this.ccRecipientArray.push({
            address: this.fromUser.email,
            isContact: true,
          });
        }

        break;
      }
      case 'client': {
        this.directRecipient = {
          address: primaryContactEmail,
          isContact: this.opportunity.clientContacts.some(
            (c) => sanitize(c.email) === primaryContactEmail
          ),
        };

        this.ccRecipientArray = ccContacts
          .filter((c) => c !== this.directRecipient.address)
          .map((r) => ({
            address: r,
            isContact: true,
          }));

        break;
      }
    }

    this.ccRecipientArray.push({ address: this.sharedEmail, isContact: true });
  }

  private getTimeAndTZAbbreviation(
    datetime: string,
    tzName?: string
  ): { formattedDateTime: string; abbr: string } {
    const momentTime = moment(datetime).tz(tzName || 'UTC');

    return {
      formattedDateTime: momentTime.format('dddd D MMMM, h:mm a'),
      abbr: momentTime.zoneAbbr(),
    };
  }
}
