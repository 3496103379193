<div data-test-id="settings-form-component-area" class="flex flex-col gap-2.5">
  <div data-test-id="settings-form-project-configuration" class="flex flex-col gap-2.5">
    <div class="flex flex-row flex-wrap gap-2.5">
      <ct-slide-toggle [value]="opportunity.callRecording" label="RECORDING"
        tooltip="New calls will be set to record if the expert has also authorised recording. Can be overridden when scheduling calls."
        (toggleChangeSignal)="onChange($event, 'callRecording')" data-test-id="settings-form-toggle">
      </ct-slide-toggle>

      <ct-slide-toggle [value]="opportunity.inProjectReferralEnabled" [label]="'IN-PROJECT REFERRALS'"
        tooltip="An option to refer experts is available in the expert portal when active"
        (toggleChangeSignal)="onChange($event, 'inProjectReferralEnabled')" data-test-id="settings-form-toggle">
      </ct-slide-toggle>
    </div>
  </div>

  <mat-accordion class="settings-form-advanced-project-settings-accordion" multi
    data-test-id="settings-form-advanced-project-settings-accordion">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title data-test-id="settings-form-advanced-project-settings-accordion-title">
          Advanced project settings
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div data-test-id="settings-form-advanced-project-settings-accordion-content">
        <div data-test-id="settings-form-advanced-project-settings-main-group">
          <ct-slide-toggle [value]="opportunity.excludeExpertPIIForClientEmails"
            [label]="'EXCLUDE EXPERT PII FOR CLIENT EMAILS'" [disabled]="opportunity.enrichedSchedulerInvite"
            [tooltip]="opportunity.enrichedSchedulerInvite ? 'Enriched content must be turned off in order to disallow PII in client emails.' : 'If active when sending client emails expert\'s will be referred to by their alias or initials.'"
            (toggleChangeSignal)="onChange($event, 'excludeExpertPIIForClientEmails')"
            data-test-id="settings-form-toggle">
          </ct-slide-toggle>

          <ct-slide-toggle [value]="opportunity.screenerComparisonEnabled" label="SHOW SCREENER COMPARISON IN PORTAL"
            tooltip="Show the screener comparison option in the client portal"
            (toggleChangeSignal)="onChange($event, 'screenerComparisonEnabled')" data-test-id="settings-form-toggle">
          </ct-slide-toggle>
        </div>

        <div data-test-id="settings-form-advanced-project-settings-scheduling-settings-group">
          <h4 data-test-id="settings-form-advanced-settings-project-scheduling-settings-group-title">
            Scheduling settings
          </h4>
          <ct-slide-toggle [value]="opportunity.enrichedSchedulerInvite" [label]="'ENRICHED SCHEDULE INVITE'"
            [disabled]="opportunity.excludeExpertPIIForClientEmails"
            [tooltip]="opportunity.excludeExpertPIIForClientEmails ? 'Exclude expert PII in client emails must be turned off to enrich scheduler emails' :'If active when a call is scheduled, expert bio & screener results are included in the default client schedule email.'"
            (toggleChangeSignal)="onChange($event, 'enrichedSchedulerInvite')" data-test-id="settings-form-toggle">
          </ct-slide-toggle>

          <ct-slide-toggle [value]="opportunity.automatedScheduling" [label]="'AUTOMATED SCHEDULING'"
            tooltip="If active when an expert gets accepted, scheduling & creation of engagements will be automated."
            (toggleChangeSignal)="onChange($event, 'automatedScheduling')" data-test-id="settings-form-toggle">
          </ct-slide-toggle>

          <ct-slide-toggle [value]="opportunity.blind" label="BLIND"
            tooltip="In a blind opportunity experts & client should not know any identifiable information about each other and the scheduler will create seperate emails for them."
            (toggleChangeSignal)="onChange($event, 'blind')" data-test-id="settings-form-toggle">
          </ct-slide-toggle>

          <ct-slide-toggle [value]="opportunity.isClientHostEnabled" [label]="'CLIENT\'S CONFERENCE HOST LINK'"
            tooltip="Recipients of the client email will receive the host link in emails when active"
            (toggleChangeSignal)="onChange($event, 'isClientHostEnabled')" data-test-id="settings-form-toggle">
          </ct-slide-toggle>
        </div>

        <div data-test-id="settings-form-advanced-project-settings-expert-communication-settings-group">
          <h4 data-test-id="settings-form-advanced-settings-project-expert-communication-settings-group-title">
            Expert communication settings
          </h4>
          <ct-slide-toggle [value]="opportunity.expertBlockProfileOutreachEnabled"
            [label]="'NO OUTREACH TO MISMATCHED EXPERTS'"
            tooltip="Will stop outreach to experts that are blocked by their profile outreach status when active"
            (toggleChangeSignal)="onChange($event, 'expertBlockProfileOutreachEnabled')"
            data-test-id="settings-form-toggle">
          </ct-slide-toggle>

          <ct-slide-toggle [value]="opportunity.sendExpertPaymentEmail" [label]="'AUTOMATED EXPERT PAYMENT EMAIL'"
            tooltip="If active, when an expert engagement payment gets set to active, a 'thank you for attending the conference' email is sent to the expert."
            (toggleChangeSignal)="onChange($event, 'sendExpertPaymentEmail')" data-test-id="settings-form-toggle">
          </ct-slide-toggle>
        </div>

        <div data-test-id="settings-form-advanced-project-settings-developer-settings-group">
          <h4 data-test-id="settings-form-advanced-settings-project-developer-settings-group-title">
            Developer settings
          </h4>
          <ct-slide-toggle [value]="opportunity.profileEditEnabled" [label]="'EXPERT PROFILE EDIT'"
            tooltip="An option which will allows expert portal users to navigate to the app to update their profile"
            (toggleChangeSignal)="onChange($event, 'profileEditEnabled')" data-test-id="settings-form-toggle">
          </ct-slide-toggle>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>